import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "dropdown",
    "textfield",
  ]

  connect() {
    this.resolve()
  }

  resolve() {
    const currentValue = this.dropdownTarget.value

    if (currentValue.length == 0) {
      this.enableTextfield()
    } else {
      this.disableTextfield()
    }
  }

  disableTextfield() {
    this.textfieldTarget.value = ''
    this.textfieldTarget.disabled = true
    this.textfieldTarget.classList.toggle('disabled')
  }

  enableTextfield() {
    this.textfieldTarget.disabled = false
    this.textfieldTarget.classList.toggle('disabled')
  }
}
