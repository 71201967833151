import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static targets = ["expiresOn", "interval", "intervalUnit"]
  static values = {
    initialMode: String,
  }

  initialize() {
    this.element.querySelectorAll('.datepicker').forEach(el => { flatpickr(el) })

    switch (this.initialModeValue) {
      case "fixed":
        this.fixedMode()
        break
      case "relative":
        this.relativeMode()
        break
      default:
        this.perpetualMode()
        break
    }
  }

  perpetualMode() {
    this.disable(this.intervalTarget)
    this.disable(this.intervalUnitTarget)
    this.disable(this.expiresOnTarget)
  }

  fixedMode() {
    this.disable(this.intervalTarget)
    this.disable(this.intervalUnitTarget)
    this.enable(this.expiresOnTarget)
  }

  relativeMode() {
    this.disable(this.expiresOnTarget)
    this.enable(this.intervalTarget)
    this.enable(this.intervalUnitTarget)
  }

  disable(rootEl) {
    rootEl.querySelectorAll(".form-label").forEach(el => { el.classList.add("text-muted") })
    rootEl.querySelectorAll('.form-control').forEach(el => { el.disabled = true })
  }

  enable(rootEl) {
    rootEl.querySelectorAll("label.form-label").forEach(el => { el.classList.remove("text-muted") });
    rootEl.querySelectorAll('.form-control').forEach(el => { el.disabled = false })
  }
}
