import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
import "@stripe/stripe-js"

import * as Turbo from "@hotwired/turbo"
import * as bootstrap from "bootstrap"
import flatpickr from "flatpickr"

import "./controllers"

Turbo.start()

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

flatpickr('.datepicker')
