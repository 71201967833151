import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { text: String }
  static targets = ["feedback"]

  copy() {
    navigator.clipboard.writeText(this.textValue)
    this.feedbackTarget.classList.remove("d-none")
    setTimeout(this.hide.bind(this), 2000)
  }

  hide() {
    this.feedbackTarget.classList.add("d-none")
  }
}
