import { Controller } from "@hotwired/stimulus"
import { loadStripe } from "@stripe/stripe-js"

export default class extends Controller {
  static targets = ["fields"]
  static values = {
    key: String,
    secret: String,
    returnUrl: String,
  }

  async connect() {
    this.stripe = await loadStripe(this.keyValue)
    this.elements = this.stripe.elements({ clientSecret: this.secretValue })
    const paymentElement = this.elements.create("payment")
    paymentElement.mount(this.fieldsTarget)
  }

  async submit(e) {
    e.preventDefault()

    const { stripe, elements } = this
    elements.submit()
    const { error } = await stripe.confirmPayment({
      clientSecret: this.secretValue,
      elements,
      confirmParams: {
        return_url: this.returnUrlValue
      }
    })
  }
}
