import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"

// Connects to data-controller="toast"
export default class extends Controller {
  connect() {
    Array.from(document.querySelectorAll('.toast'))
      .forEach(toastNode => new Toast(toastNode).show())
  }
}
