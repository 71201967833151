import { Controller } from "@hotwired/stimulus"

DEBOUNCE_TIMEOUT = 350

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.timer = null;
  }

  search(e) {
    this.element.submit()
  }

  debounce() {
    clearTimeout(this.timer)

    this.timer = setTimeout(this.search.bind(this), DEBOUNCE_TIMEOUT)
  }
}
