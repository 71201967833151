import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'toggle',
    'quantityInput',
  ]

  connect() {
    this.handleToggle()
  }

  handleToggle(e) {
    this.quantityInputTarget.disabled = !this.isEnabled()
  }

  isEnabled() {
    return this.toggleTarget.checked
  }
}
