import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option", "form"]

  connect() {
    this.optionTargets.forEach((option) => {
      option.addEventListener('change', this.update.bind(this));

      switch (option.dataset.controller) {
        case 'datepicker':
          option.addEventListener('close', this.foo.bind(this));
          break;
      };
    });
  }

  disconnect() {
    this.optionTargets.forEach((option) => {
      option.removeEventListener('change', this.update.bind(this));
    });
  }

  foo() {
    console.log("FOO BABY FOO");
  }

  update() {
    this.element.requestSubmit();
  }
}
