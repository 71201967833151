import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["countrySelect", "stateSelect"]
  static values = {
    us: Array,
    ca: Array,
    current: String,
  }

  initialize() {
    this.usOptions = this.buildOptions(this.usValue)
    this.caOptions = this.buildOptions(this.caValue)
  }

  connect() {
    this.states = []
    this.refresh()
  }

  onCountryChange(e) { this.refresh() }

  load() {
    switch (this.countrySelectTarget.value) {
      case 'CA':
        this.states = this.caOptions
        break
      case 'US':
        this.states = this.usOptions
        break
      default:
        this.states = []
        break
    }
  }

  refresh() {
    let selectedIndex = 0

    this.clearStateSelect()

    this.load()
    this.states.forEach((state, idx) => {
      this.stateSelectTarget.add(state)

      if (this.currentValue === state.value) {
        selectedIndex = idx
      }

      this.stateSelectTarget.selectedIndex = selectedIndex
    });

    this.toggleStateSelect()
  }

  buildOptions(values) {
    return values.map(option => new Option(option[1], option[0]))
  }

  clearStateSelect() {
    const { stateSelectTarget } = this
    while (stateSelectTarget.options.length > 0) {
      stateSelectTarget.remove(0)
    }
  }

  toggleStateSelect() {
    this.stateSelectTarget.disabled = this.stateSelectTarget.options.length === 0;
  }
}
