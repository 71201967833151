import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element)
    this.element.addEventListener('hidden.bs.modal', this.removeFromDom.bind(this))
    this.modal.show()
  }

  removeFromDom() {
    this.element.remove()
  }
}
