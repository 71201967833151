import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

// https://flatpickr.js.org/formatting/
const DefaultFormat = "Z"
export default class extends Controller {
  static values = {
    enableTime: { type: Boolean, default: false },
    dateFormat: String,
  }

  connect() {
    this.options = {
      altFormat: this.dateFormatValue,
      altInput: true,
      dateFormat: DefaultFormat,
      enableTime: this.enableTimeValue,
    }

    flatpickr(this.element, this.options);
  }

  submit() {
    console.log("submit")
  }
}
