import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'toggle',
    'helpText',
    'options',
  ]

  connect() {
    if (this.toggleTarget.checked) {
      this.showOptions()
    } else {
      this.hideOptions()
    }
  }

  handleToggle(e) {
    if (this.toggleTarget.checked) {
      this.showOptions()
    } else {
      this.hideOptions()
    }
  }

  showOptions() {
    this.helpTextTarget.classList.add("d-none")
    this.optionsTarget.classList.remove("d-none")
  }

  hideOptions() {
    this.helpTextTarget.classList.remove("d-none")
    this.optionsTarget.classList.add("d-none")
  }
}
